import React from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';
import ActaAuthProvider from 'pa_kit/auth';
import AppRoutes from './approutes';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-italia/dist/css/bootstrap-italia.min.css';
import 'font-awesome/css/font-awesome.min.css';
import 'react-bootstrap-drawer/lib/style.css';
import 'css/index.css';
import 'css/app.css';

import 'typeface-titillium-web';
import 'typeface-roboto-mono';
import 'typeface-lora';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>        
        <HelmetProvider>
            <ActaAuthProvider>
                <AppRoutes />
            </ActaAuthProvider>
        </HelmetProvider>        
    </React.StrictMode>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
